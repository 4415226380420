<template>
  <div style=" width:100%;;max-width: 600px;margin: 0 auto;">
    <div style="background-color: rgba(82,196,26,0.65)">
      <div style="padding: 10px;font-size: 20px;font-weight: 600;color: #ffffff;text-align: center">
        <span v-if="batch.sourceManagementBatch.plantVarietyName ">{{batch.sourceManagementBatch.plantVarietyName}}</span>溯源档案
      </div>
    </div>
    <div style="background: #fff">
      <template>
        <a-carousel>
          <div><h3><img style="width: 100%;max-height: 500px" :src="batch.sourceManagement.coverImgAll"/></h3></div>
        </a-carousel>
      </template>
    </div>
    <div style="background: #fff;margin-left: 15px;margin-right: 15px">
      <span style="font-size: 25px;font-weight: 700;color: black">{{batch.sourceManagement.name}}</span>
      <div style="margin-top: 5px;color: black">
        <span>品种:</span>
        <span style="margin-left: 8px;">{{batch.sourceManagementBatch.plantVarietyName}}</span>
      </div>
      <div style="margin-top: 5px;color: black">
        <span>上市时间:</span>
        <span style="margin-left: 8px;">{{batch.sourceManagementBatch.startTime | dateFormatFull}}</span>
      </div>
      <div style="margin-top: 5px;color: black">
        <span>溯源编号:</span>
        <span style="margin-left: 8px;">{{batch.sourceManagementBatch.codeUrl}}</span>
      </div>
    </div>
    <a-divider/>
    <div style="margin-top: 1.5rem">
      <div style="display: flex; justify-content: space-between;margin-bottom: 1rem;margin-left: 15px;margin-right: 15px">
        <h4 style="font-size: 15px;font-weight: 600;color: black;" >检测证书</h4>
      </div>
      <div style="margin-left: 15px;margin-right: 15px">
        <div v-for="sourceCertificate in batch.sourceCertificateList" :key="sourceCertificate.id"  style="margin-top: 15px">
          <a-row type="flex">
            <a-col style="width: 70px;">
              <div style="height: 100px;width: auto">
                <el-image
                    style="height: 100px;width: auto "
                    :src="sourceCertificate.certificateImgAll"
                    :fit="'cover'"></el-image>
              </div>
            </a-col>
            <a-col style="flex: 1;">
              <div style="margin-left: 30px">
                <div style="margin-top: 3px">
                  <span>证书编号:</span>
                  <span style="margin-left: 8px;color: black">{{sourceCertificate.certificateCode}}</span>
                </div>
                <div style="margin-top: 3px">
                  <span>检测机构:</span>
                  <span style="margin-left: 8px;color: black">{{sourceCertificate.certificateOrganization}}</span>
                </div>
                <div style="margin-top: 3px">
                  <span>颁发时间:</span>
                  <span style="margin-left: 8px;color: black">{{sourceCertificate.startTime | dateFormatFull}}</span>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>

    <a-divider/>
    <div style="margin-top: 1.5rem">
      <div style="display: flex; justify-content: space-between;margin-bottom: 1rem;margin-left: 15px;margin-right: 15px">
        <h4 style="font-size: 15px;font-weight: 600;color: black;">销售渠道</h4>
      </div>
      <div style="margin-left: 15px;margin-right: 15px">
        <div v-for="sm in batch.sourceMarketList" :key="sm.id" >
          <a-row>
            <a-col :span="6"><span>类型:&nbsp;&nbsp;</span><span>{{sm.type | marketTypeFilter}}</span></a-col>
            <a-col :span="8">
              <div style="width:100%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <span>名称:&nbsp;&nbsp;</span><span>{{sm.name}}</span>
              </div>
            </a-col>
            <a-col :span="10">
              <div style="width:100%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><span>地址:&nbsp;&nbsp;</span>
                <a :href="sm.address" target="_blank" v-if="sm.type === 'ONLINE'" style="color: rgba(0, 0, 0, 0.65);">点击跳转</a>
                <span v-else>{{sm.address}}</span>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>

    <a-divider/>
    <div style="margin-top: 1.5rem">
      <div style="display: flex; justify-content: space-between;margin-bottom: 1rem;margin-left: 15px;margin-right: 15px">
        <h4 style="font-size: 15px;font-weight: 600;color: black;">环境参数</h4>
      </div>
      <div style="margin-left: 0px;margin-right: 0px">
        <div id="charCurved"></div>
      </div>
    </div>
    <a-divider/>
    <div style="margin-top: 1.5rem">
      <div style="display: flex; justify-content: space-between;margin-bottom: 1rem;margin-left: 15px;margin-right: 15px">
        <h4 style="font-size: 15px;font-weight: 600;color: black;">产品溯源</h4>
      </div>
      <div style="margin-left: 5px;margin-right: 5px">
        <template>
          <div>
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="农事任务">
                <div style="margin-top: 10px;margin-left: 5px">
                  <template>
                    <a-timeline>
                      <a-timeline-item color="green" v-for="cropManagement in batch.cropManagementList" :key="cropManagement.id">{{cropManagement.assetWarehouseAndDosage}} {{cropManagement.startTime | dateFormat}}</a-timeline-item>
                    </a-timeline>
                  </template>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="检测管理" force-render>
                <div v-for="sourceDetection in batch.sourceDetectionList" :key="sourceDetection.id" style="margin-top: 10px;margin-left: 5px">
                  <div style="margin-top: 5px;color: black">
                    <span>检测负责人:</span>
                    <span style="margin-left: 8px;">{{sourceDetection.principal}}</span>
                  </div>
                  <div style="margin-top: 5px;color: black">
                    <span>检测时间:</span>
                    <span style="margin-left: 8px;">{{sourceDetection.recordDate | dateFormatFull}}</span>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="3" tab="加工管理">
                <div v-for="sourceProcess in batch.sourceProcessList"  :key="sourceProcess.id" style="margin-top: 10px;margin-left: 5px">
                  <div style="margin-top: 5px;color: black">
                    <span>内容:</span>
                    <span style="margin-left: 8px;">{{sourceProcess.text}}</span>
                  </div>
                  <div style="margin-top: 5px;color: black">
                    <span>操作人:</span>
                    <span style="margin-left: 8px;">{{sourceProcess.principal}}</span>
                  </div>
                  <div style="margin-top: 5px;color: black">
                    <span>开始时间:</span>
                    <span style="margin-left: 8px;">{{sourceProcess.startTime | dateFormatFull}}</span>
                  </div>
                  <div style="margin-top: 5px;color: black">
                    <span>结束时间:</span>
                    <span style="margin-left: 8px;">{{sourceProcess.endTime | dateFormatFull}}</span>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="4" tab="配送管理">
                <div v-for="sourceDistribution in batch.sourceDistributionList" :key="sourceDistribution.id" style="margin-top: 10px;margin-left: 5px">
                  <div style="margin-top: 5px;color: black">
                    <span>出发地:</span>
                    <span style="margin-left: 8px;">{{sourceDistribution.startArea}}</span>
                  </div>
                  <div style="margin-top: 5px;color: black">
                    <span>目的地:</span>
                    <span style="margin-left: 8px;">{{sourceDistribution.endArea}}</span>
                  </div>
                  <div style="margin-top: 5px;color: black">
                    <span>负责人:</span>
                    <span style="margin-left: 8px;">{{sourceDistribution.principal}}</span>
                  </div>
                  <div style="margin-top: 5px;color: black">
                    <span>收货人:</span>
                    <span style="margin-left: 8px;">{{sourceDistribution.consignee}}</span>
                  </div>
                  <div style="margin-top: 5px;color: black">
                    <span>配送时间:</span>
                    <span style="margin-left: 8px;">{{sourceDistribution.startTime | dateFormatFull}}</span>
                  </div>
                  <div style="margin-top: 5px;color: black">
                    <span>到达时间:</span>
                    <span style="margin-left: 8px;">{{sourceDistribution.endTime | dateFormatFull}}</span>
                  </div>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </template>
      </div>
    </div>


    <a-divider/>
    <div style="margin-top: 1.5rem">
      <div style="display: flex; justify-content: space-between;margin-bottom: 1rem;margin-left: 15px;margin-right: 15px">
        <h4 style="font-size: 15px;font-weight: 600;color: black;">基地简介</h4>
      </div>
      <div style="background: #fff;margin-left: 15px;margin-right: 15px">
        <div style="margin-top: 5px;color: black">
          <span>基地名称:</span>
          <span style="margin-left: 8px;">{{batch.farmArea.name}}</span>
        </div>
        <div style="margin-top: 5px;color: black">
          <span>面积:</span>
          <span style="margin-left: 8px;">{{batch.farmArea.acreage}}</span>
        </div>
        <div style="margin-top: 5px;color: black">
          <span>负责人:</span>
          <span style="margin-left: 8px;">{{batch.farmArea.contact}}</span>
        </div>
      </div>
    </div>
    <a-divider/>
    <div style="margin-top: 1.5rem">
      <div style="display: flex; justify-content: space-between;margin-bottom: 1rem;margin-left: 15px;margin-right: 15px">
        <h4 style="font-size: 15px;font-weight: 600;color: black;">公司简介</h4>
      </div>
      <div style="margin-left: 15px;margin-right: 15px">
        <span style="color:#000;">
            {{batch.organization.introduction}}
        </span>
      </div>
    </div>
    <div style="height: 20px">

    </div>
  </div>
</template>

<script>
    import {Chart} from "@antv/g2";

    export default {
        name: "SourceMobile",
        data() {
            return {
                code: '',
                batch: {
                    sourceManagementBatch:{
                        plantVarietyName:'',
                        startTime:'',
                        codeUrl:'',
                    },
                    sourceManagement:{
                        coverImgAll:'',
                        name:'',
                    },
                    sourceCertificateList:[],
                    sourceMarketList:[],
                    cropManagementList: [],
                    sourceDetectionList: [],
                    sourceProcessList: [],
                    sourceDistributionList: [],
                    organization: {
                        introduction:''
                    },
                    farmArea:{
                        name:'',
                        acreage:'',
                        contact:'',
                    }
                },
                charObj: [],
                environmentData: [],


            }
        },
        watch: {},
        filters: {
            marketTypeFilter: function (value) {
                if (!value) return "";
                if (value === 'ONLINE') {
                    return "线上";
                } else if (value === 'OFFLINE') {
                    return "线下";
                } else {
                    return "";
                }
            },
        },
        computed: {},
        watch: {
            'environmentData': function (val) {
                this.charObj.data(this.environmentData);
                this.charObj.render();
            },
        },
        mounted() {
            this.code = this.$route.query.code;
            this.getSourceManagementBatchByBatchCode();
        },
        created() {
            this.initCharCurved().then(() => {
                this.getSourceManagementEnvironmentData();
            });
        },
        methods: {
            initCharCurved() {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        this.$nextTick(() => {
                            this.charObj = new Chart({
                                container: 'charCurved',
                                autoFit: true,
                                height: 260,
                                padding: [25, 35,45, 55], // 上，右，下，左
                            });
                            this.charObj.scale({
                                collectItem: {
                                    range: [0, 1],
                                },
                                value: {
                                    alias: '值',
                                    nice: true,
                                },
                            });

                            this.charObj.tooltip({
                                showCrosshairs: true,
                                shared: true,
                            });

                            this.charObj.axis('value', {});
                            this.charObj.area().adjust('stack').position('time*value').color('collectItem');
                            this.charObj.line().adjust('stack').position('time*value').color('collectItem');
                            this.charObj.interaction('element-highlight');


                            // 复写 图例筛选 交互。
                            // 1、点击图例名称 进行 unchecked 状态的切换
                            // 2、点击图例 marker，进行 checked 状态的切换（进行聚焦）
                            // 3、双击 重置状态
                            this.charObj.interaction('legend-filter', {
                                start: [
                                    {trigger: 'legend-item-name:click', action: ['list-unchecked:toggle', 'data-filter:filter']},
                                    {trigger: 'legend-item-marker:click', action: ['list-checked:checked', 'data-filter:filter']},
                                ],
                                end: [{
                                    trigger: 'legend-item-marker:dblclick',
                                    action: ['list-checked:reset', 'data-filter:filter']
                                }],
                            });
                            resolve(this.charObj);
                        });
                    }, 500);
                });
            },
            async getSourceManagementBatchByBatchCode() {
                const {data: res} = await this.http.sourceManagement.getSourceManagementBatchByBatchCode({code: this.code});
                if (res.status !== 200) {
                    this.$message.error(res.msg);
                }
                this.batch = res.data;
            },
            async getSourceManagementEnvironmentData() {
                const {data: res} = await this.http.sourceManagement.getSourceManagementEnvironmentData({code: this.code});
                if (res.status !== 200) {
                    this.$message.error(res.msg);
                }
                this.environmentData = res.data;
            },
        }
    }
</script>

<style scoped>
  h4::before{
    content: "";
    border-left: 3px solid rgba(82,196,26,0.65);
    height: 1rem;
    margin-right: 0.5rem;
  }
</style>
